@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

$theme-colors: (
        primary: #00467F,
        danger: #dc3545
);

@import "bootstrap";

.formCard {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  border-top: solid 5px map-get($theme-colors, primary);
  border-radius: 0
}

.formCardBody {
  display: flex;
  align-items: center;
  justify-content: center;
}

.formCardFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: right;
}

@media (min-width: 800px) {
  .initialPage  {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 799px) {
  .initialPage  {
    position: fixed;
    top: 50%;
    transform: translate(0%, -50%);
  }
}
